// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import HowTo from 'components/Contentful/HowTo'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Referenzgeschichte Familie Eustache"
        meta={[
          { name: 'description', content: 'Die Familie Eustache aus aus Biel-Benken BL war von der «Tag der offenen Wärmepumpe» begeistert. Gleich reinschauen!' },
          { name: 'keywords', content: 'Erfahrungsbericht Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='3swr4Mf5iCbPQlaXQ0t9dS-2vp4APwlfHFkLWVEEl7Aeg'
          id='familie-eustache-biel-benken-bl'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "2vp4APwlfHFkLWVEEl7Aeg",
    "updatedAt": "2021-02-10T08:22:33.453Z",
    "title": "FAMILIE EUSTACHE, BIEL-BENKEN BL",
    "size": "Groß",
    "teaser": "Familie Eustache besass schon eine Photovoltaikanlage – folglich kamen als Heizungsersatz ausschliesslich Wärmepumpen in Frage. Drei Jahre lang hat sich Familie Eustache mit diversen Wärmepumpen-Anbietern auseinandergesetzt. Nach der Teilnahme am «Tag der offenen Wärmepumpe», welcher regelmässig von der Heizungsmacher AG durchgeführt wird, war rasch klar – die Wärmepumpe soll von den Heizungsmachern eingebaut werden.",
    "image": {
        "description": "Ein Foto von der neuen Wärmepumpe der Familie Eustache",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/6BGl27VEQvq8dmMX03vy18/11c62df86387513f7b9352fbe61b64a4/2---Bild-WP---formatiert---bearbeitet---HERO.png"
    },
    "links": [
        {
            "type": "link",
            "id": "5Rv4CldRK0aI4UGakKsISC",
            "updatedAt": "2022-04-01T07:44:51.656Z",
            "label": "Zur Referenz-Übersicht",
            "page": {
                "slug": "referenzen"
            }
        }
    ],
    "anchorName": "familie-eustache-biel-benken-bl"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='3swr4Mf5iCbPQlaXQ0t9dS-5V3Jolfmv22ryjNNQpAIQZ'
          id=''
        >
          <HowTo
            {...{
    "type": "howTo",
    "id": "5V3Jolfmv22ryjNNQpAIQZ",
    "updatedAt": "2021-02-10T08:48:28.423Z",
    "title": "«Der Tag der offenen Wärmepumpe war ausschlaggebend»",
    "steps": [
        {
            "type": "howToStep",
            "id": "37m9h6j2djQFUvBA3UvaeN",
            "updatedAt": "2022-05-10T07:10:13.485Z",
            "title": "Umweltschutz im Alltag",
            "description": "<p>Familie Eustache suchte eine Lösung, wie sie den <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/#mit-erneuerbarer-energie-geld-und-co2-einsparen\">Umweltschutz</a> mit Ihrem Alltag vereinbaren könnte. Daher haben sie sich vor wenigen Jahren eine <a href=\"https://www.heizungsmacher.ch/waermepumpe-photovoltaik/\">Photovoltaik-Anlage</a> einbauen lassen. Als <a href=\"https://www.heizungsmacher.ch/heizungsersatz/\">Heizungsersatz</a> kam in diesem Fall für die Familie nur noch eine <a href=\"https://www.heizungsmacher.ch/waermepumpen/\">Wärmepumpe</a> in Frage. Um sich einen Überblick zu verschaffen, startete Familie Eustache mit der Internet-Recherche. Guy Eustache sagt: «Durch die Empfehlung von einem bedeutenden Wärmepumpen-Hersteller stiessen wir schliesslich auf die Heizungsmacher AG.» Er fügt hinzu: <strong>«Am Tag der offenen Wärmepumpe konnten wir schliesslich die <a href=\"https://www.heizungsmacher.ch/wissen/wie-funktioniert-eine-waermepumpe/\">Funktionsweise der Heizanlage</a> vor Ort erleben und das Heizungsmacher-Team kennenlernen. Wir waren begeistert.»</strong></p>\n",
            "image": {
                "description": "Ein Bild vom neuen Kombi-Speicher der Marke Stiebel-Eltron",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2kK1eEuwd4KVO1FAXxeEgp/e6c2594f5ca3ac5db11728ee47459916/3---Bild-Boiler---formatiert---bearbeiter---Anleitungsschritt.png"
            }
        },
        {
            "type": "howToStep",
            "id": "5V5lmbjUePvOro2uhikurs",
            "updatedAt": "2021-06-11T07:28:34.635Z",
            "title": "Den Geruch der Ölheizung endlich losgeworden",
            "description": "<p>Guy Eustache schmunzelt: <strong>«Es riecht nicht mehr nach Öl und wir sind sehr froh darüber. Auch freuten wir uns darüber, dass die Heizungsmacher AG das Abpumpen und Entfernen des Öltankes organisiert hat und wir dadurch einen <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/#mehr-raum-durch-platzsparende-ersatz-loesung\">zusätzlichen Kellerraum</a> gewonnen haben.»</strong></p>\n",
            "image": {
                "description": "Ein Bild von der neuen Wärmepumpe der Familie Eustache",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3rkqiw0nNys3EcxZXXlJQW/f7d1e3ce7da6b28dfec8aa7dfc3cff54/1-2--Bild-WP-formatiert.png"
            }
        },
        {
            "type": "howToStep",
            "id": "21M7z2j29fEmvjJPNhkKgq",
            "updatedAt": "2021-06-11T07:28:51.942Z",
            "title": "Die Erfahrung während und nach der Bauphase",
            "description": "<p>Guy Eustache: «<strong>Der Terminplan, welcher vom <a href=\"https://www.heizungsmacher.ch/ueber-uns/\">Heizungsexperten</a> und Projektleiter von der Heizungsmacher AG, Marcel Heer erstellt worden ist, wurde von den lokalen Handwerkern penibel eingehalten und einwandfrei ausgeführt.</strong> Darüber sind wir sehr froh.» Familie Eustache war verwundert, wieso der Projektleiter von der Heizungsmacher AG nicht regelmässig vor Ort war. Dank der Zusammenarbeit mit qualifizierten, kompetenten und langjährigen Partnern aus der Region, besteht in den meisten Fällen kein Bedarf, dass der Projektleiter während der gesamten Bauphase vor Ort anwesend sein muss. <strong>Der Projektleiter hat die gesamte Planung ständig im Visier und ist im regen Austausch mit den Partnern vor Ort und steht bei Unklarheiten jederzeit zur Verfügung.</strong></p>\n",
            "image": {
                "description": "die frisch angemalte Wärmepumpe der Familie Eustache",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3bUwxs9YQFws8WoHPhsXLv/94966ea3cb777e608c006079e6968515/6-Bild-der-angemalten-WP.png"
            }
        },
        {
            "type": "howToStep",
            "id": "1WLiqnsdcgstupAVlCGIUY",
            "updatedAt": "2021-06-11T07:29:15.728Z",
            "title": "Freundlicher Kundendienst – rasche Reaktionszeit",
            "description": "<p><strong>«Für uns ist die <a href=\"https://www.heizungsmacher.ch/kontakt/\">Erreichbarkeit</a> sehr wichtig und die Heizungsmacher konnte man anrufen und man erhielt auch immer eine rasch Rückmeldung. Mit den Problemlösungen waren wir immer sehr zufrieden.»</strong> Die Unsicherheit des Lockdowns hat Guy Eustache auch bei der Heizungsmacher AG gespürt. Als der Projektleiter unglücklicherweise krank geworden ist, musste kurzfristig die Kontaktperson gewechselt werden. Der Kundendienst ist jedoch auch hier Familie Eustache stets zur Seite gestanden und im Hintergrund wurde das Projekt lückenlos ausgeführt und die Bauarbeiten liefen kontinuierlich weiter. Wir versichern, dass wir auch für solche Situationen bestens ausgerüstet sind um die Projekte nahtlos weiterzuführen.</p>\n<p><strong>Lachend erzählt Familie Eustache: «Unsere Grosskinder haben unsere Wärmepumpe angemalt</strong>, der Markt soll schönere <a href=\"https://www.heizungsmacher.ch/waermepumpen/\">Wärmepumpen</a> anbieten.» Selbstverständlich nehmen wir das mit Humor auf und freuen uns immer wieder, wenn die Wärmepumpen von unseren Kunden nach eigenem Geschmack veredelt werden.</p>\n",
            "image": {
                "description": "Ein Bild von der angemalten Wärmepumpe der Familie Eustache",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/65Ls8vgSysA4xFFTphqn10/38b01f14f3d42b6041b81d46ad67a40d/5-Bild-der-angemalten-WP.png"
            }
        }
    ]
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='3swr4Mf5iCbPQlaXQ0t9dS-hBwFkvBF4GYbnrWMUsoAL'
          id='feedback-familie-eustache'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "hBwFkvBF4GYbnrWMUsoAL",
    "updatedAt": "2023-03-01T09:08:01.428Z",
    "title": "Feedback Familie Eustache",
    "anchorName": "feedback-familie-eustache",
    "text": "<p>Heizungsmacher-Team: <em>Sehr geeehrte Familie Eustache. Vielen Dank für Ihre Bewertung und Weiterempfehlung.</em></p>\n",
    "image": {
        "description": "4.5 Sterne-Bewertung",
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/6aBuZ37ZO2dvjOfCd4WqXh/7e2a24834185cf6eb75e42d1a22d894d/4.5_Sterne-Bewertung.svg"
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='3swr4Mf5iCbPQlaXQ0t9dS-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
